











import Vue from "vue";
import Menu from "./components/Menu.vue";
import { IMenuButton } from "./interfaces";
import { IRepository, repositoryGetter } from "@netvision/lib-api-repo";

export default Vue.extend({
  name: "App",
  components: {
    Menu,
  },
  data() {
    return {
      $api: {} as IRepository,
    };
  },
  computed: {
    withTenantQuery(): boolean {
      return Boolean(!this.$parent?.props?.noTenant);
    },
    menuButtons(): IMenuButton[] {
      return this.$parent?.props?.menuButtons || [];
    },
  },
  beforeMount() {
    this.$api = repositoryGetter(this.$parent?.props?.lib?.name);
  },
});
