import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import { RUSSIAN_TRANSLATIONS } from "./translations/ru";

import Button from "primevue/button";
Vue.component("Button", Button);
import SelectButton from "primevue/selectbutton";
Vue.component("SelectButton", SelectButton);
import SplitButton from "primevue/splitbutton";
Vue.component("SplitButton", SplitButton);
import Message from "primevue/message";
Vue.component("Message", Message);
import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);
import Toast from "primevue/toast";
Vue.component("Toast", Toast);
import ToastService from "primevue/toastservice";
Vue.use(ToastService);
//@ts-ignore
import VueTeleport from "@desislavsd/vue-teleport";
Vue.use(VueTeleport);

import App from "./App.vue";
Vue.use(VueRouter);
Vue.use(VueI18n);

Vue.prototype.$primevue = { ripple: true };
Vue.config.productionTip = false;
const routes = [] as any;
const router = new VueRouter({
  base: __dirname,
  mode: "history",
  routes,
});

const locale = "ru";
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
};
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    i18n,
    render: (h: any) => h(App),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
export const update = vueLifecycles.update;
