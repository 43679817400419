










































































































import Vue from "vue";
import { IMenuButton } from "../interfaces";
import { name as appName } from "../../package.json";
import { get as getFromIDB, set as setInIDB } from "idb-keyval";
import { IRepository } from "@netvision/lib-api-repo";

const themeEl = document.getElementById("theme") as HTMLLinkElement & {
  setTheme: (value: string) => void;
};
const setTheme = (value: boolean) => themeEl.setTheme(value ? "dark" : "light");
const getTheme = () => themeEl.getAttribute("theme-name") === "dark";
const themeSubscribe = (func: (newValue: boolean) => void) => {
  const listener = (e: any) => func(e.detail === "dark");
  themeEl.addEventListener("update", listener);
  return () => themeEl.removeEventListener("update", listener);
};

export default Vue.extend({
  name: "Menu",
  props: {
    withTenantQuery: {
      type: Boolean,
      required: true,
    },
    menuButtons: {
      type: Array as () => IMenuButton[],
      required: true,
    },
    api: {
      type: Object as () => IRepository,
      required: true,
    },
  },
  data() {
    return {
      currentPath: "" as string,
      currentRoutePath: "" as string,
      isDarkTheme: true,
      currentSwitcherPath: "",
      unmount: () => {},
      requiredScopes: new Set() as Set<string>,
      permissionScopes: new Set() as Set<string>,
      userId: "",
      userFullName: "",
      themeUnsubscribe: () => {},
      menuButtonsPaths: [] as string[],
      warningMessagePanel: {} as IWarningMessagePanel,
    };
  },
  computed: {
    switchButton: {
      get(): Object | null {
        if (this.currentMenuButton && this.currentMenuButton.switchers) {
          return (
            this.currentMenuButton.switchers.find(({ path }) =>
              this.isCurrent(path)
            ) || null
          );
        }
        return null;
      },
      set(button) {
        this.menuButtonClick(button);
      },
    },
    currentBasePath(): string {
      const reg = /^\/\w+[^/?]/;
      return (this.currentRoutePath.match(reg) || [""])[0];
    },
    currentMenuButton(): IMenuButton | null {
      if (this.currentRoutePath !== "") {
        const findedItem = this.menuButtons.find((e: IMenuButton) => {
          const reg = new RegExp(`^${e.path}`);
          return reg.test(this.currentRoutePath);
        });
        return findedItem || null;
      }
      return null;
    },
    isLicenseExpiring(): boolean {
      return (
        this.withTenantQuery && this.warningMessagePanel?.type !== undefined
      );
    },
  },
  watch: {
    $route() {
      this.currentRoutePath = this.$router.currentRoute.fullPath;
      const paths = this.menuButtonsPaths.filter((val) => {
        return new RegExp(`^${val}`).test(this.$router.currentRoute.fullPath);
      });
      if (paths.length) {
        paths.forEach((path) => {
          localStorage.setItem(
            `${appName}:menuButton${path}${this.userId}`,
            JSON.stringify({
              path: this.$router.currentRoute.path,
              query: this.$router.currentRoute.query,
            })
          );
        });
      }
    },
    isDarkTheme() {
      setTheme(this.isDarkTheme);
    },
  },
  methods: {
    canI(scopes: string[]) {
      return scopes.some((e) => this.permissionScopes.has(e));
    },
    logout() {
      this.api
        .logout()
        .then((res: boolean) => {
          if (res === true) {
            return (document.location.href = "/");
          }

          throw new Error("unknown error");
        })
        .catch((error: Error) => {
          this.$toast.add({
            severity: "error",
            summary: "Ошибка",
            detail: error.message,
            life: 5000,
          });
        });
    },
    pathGenerator(button: IMenuButton, clearQuery = false) {
      const storedRoute = localStorage.getItem(
        `${appName}:menuButton${button.path}${this.userId}`
      );
      return storedRoute !== null && !clearQuery
        ? JSON.parse(storedRoute)
        : {
            path: button.path + (button.defaultPath || ""),
            query: button.query ? button.query : {},
          };
    },
    menuButtonClick(button: any) {
      const path = this.pathGenerator(button);
      this.$router.push(path).catch(() => {});
    },
    isCurrent(path: string) {
      const reg = new RegExp(`^${path}`);
      return reg.test(this.currentRoutePath);
    },
    getRequiredScopes(menuButtons: IMenuButton[]) {
      for (let menuButton of menuButtons) {
        if (menuButton.scopes) {
          menuButton.scopes.forEach((e: string) => {
            this.requiredScopes.add(e);
          });
        }
      }
    },
    checkLicenseExpiring(licenseInfo: ILicenseInfo) {
      const currentDateTimestamp = new Date().getTime();
      const licenseExpiringDateTimestamp = new Date(
        licenseInfo.licenseInfoExpiration
      ).getTime();
      if (!isNaN(licenseExpiringDateTimestamp)) {
        const daysDiff =
          (licenseExpiringDateTimestamp - currentDateTimestamp) /
          (1000 * 3600 * 24);
        if (daysDiff < licenseInfo.warningPeriodInDays) {
          this.warningMessagePanel = {
            text:
              daysDiff >= -1
                ? String(
                    `${this.$t("license.expiring")} ${Math.ceil(daysDiff) + 1}`
                  )
                : String(this.$t("license.expired")),
            type: daysDiff >= -1 ? "warn" : "error",
          };
        }
      }
    },
    getAndSetUserInfo() {
      if (this.api.getUserInfo) {
        this.api
          .getUserInfo()
          // @ts-ignore
          .then((response) => {
            if ("userId" in response) {
              this.userFullName = response.fullName;
              this.userId = response.userId;
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error: Error) => console.error(error));
      }
    },
  },
  async mounted() {
    if (this.withTenantQuery && this.api.getUserInfo) {
      this.api
        .checkLoggedIn()
        .then(({ isUserLogged, response }) => {
          if (response?.licenseInfoExpiration) {
            this.checkLicenseExpiring({
              licenseInfoExpiration: response.licenseInfoExpiration as string,
              warningPeriodInDays: 30,
            });
          }
          isUserLogged && this.getAndSetUserInfo();
        })
        .catch((error: Error) => console.error(error));
    }
    if (this.menuButtons.length) {
      this.menuButtonsPaths = [];
      this.menuButtons.forEach(({ path, switchers }) => {
        this.menuButtonsPaths.push(path);
        if (switchers) {
          switchers.forEach(({ path }) => {
            this.menuButtonsPaths.push(path);
          });
        }
      });
    }
    this.getRequiredScopes(this.menuButtons);
    const permissionScopes = await getFromIDB(
      `${appName}${this.userId}-permissionScopes`
    );
    permissionScopes && (this.permissionScopes = permissionScopes);
    if (this.withTenantQuery) {
      this.permissionScopes = new Set(
        await this.api.getPermissions(Array.from(this.requiredScopes))
      );
      setInIDB(
        `${appName}${this.userId}-permissionScopes`,
        this.permissionScopes
      );
    }

    this.isDarkTheme = getTheme();
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.isDarkTheme = newValue;
    });
    this.currentRoutePath = this.$router.currentRoute.fullPath;
  },
  async beforeDestroy() {
    await this.unmount();
    await this.themeUnsubscribe();
  },
});
